import { ErrorMessage, useField } from 'formik';
import React, { ReactNode } from 'react';
import Item from './CheckboxGroupItem';
import { CheckboxProvider } from './CheckboxContext';
import { FormLabel, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
  name: string;
  label: string;
};

function CheckboxGroup({ name, label, children }: Props) {
  const [field, meta, helpers] = useField<string[]>(name);
  // const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  return (
    <CheckboxProvider value={{ field, helpers, meta }}>
      <FormLabel
        display="flex"
        style={{ marginTop: '10px', marginBottom: '0px' }}
        ms="10px"
        fontSize="sm"
        color={'#111827'}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
      </FormLabel>
      <SimpleGrid columns={[1, 4]} spacing={1}>
        {children}
      </SimpleGrid>
      <ErrorMessage
        name={name}
        render={(msg) => <span style={{ color: 'red' }}>{msg}</span>}
      />
    </CheckboxProvider>
  );
}

export default Object.assign(CheckboxGroup, { Item });
