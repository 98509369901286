
// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, useColorModeValue, Text, FormErrorMessage, InputRightElement, Icon, InputGroup } from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/variants/Default';

// Assets
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { VSeparator } from 'components/separator/Separator';
import { useEffect, useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import instituteService from 'services/InstituteService';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonToast from 'views/common/Toast';
import { Guid } from 'guid-typescript';

function ForgotPassword() {
    const loc = useLocation();
    const navigate = useNavigate();
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';

    const validationSchemaforResetPwd = Yup.object().shape({
        emailID: Yup.string().email('Invalid email').required('Email is required'),
    });

    const validationSchemaforChangePwd = Yup.object().shape({
        tempPassword: Yup.string().required('Temporary Password is required').min(8, "You must enter at least 8 characters."),
        newPassword: Yup.string().required('New Password is required').min(8, "You must enter at least 8 characters."),
        confirmPassword: Yup.string().required('Confirm Password is required').min(8, "You must enter at least 8 characters."),
    });

    const [resetpwd, setResetpwd] = useState(false)
    const [show, setShow] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [mail, setMail] = useState('')
    const [loading, setLoading] = useState(false);

    const handleClick = () => setShow(!show);

    const resetPassword = (formValues: any) => {
        setMail(formValues.emailID)
        setLoading(true)
        instituteService.forgotPassword(formValues).then((res: any) => {
            setLoading(false)
            if (res?.status === 200) {
                setResetpwd(true)
                CommonToast({
                    title: 'Your temporary password has been sent to your registered email',
                    status: 'success',
                });
            }
        })
    }

    const changePassword = (formValues: any, actions: any) => {
        if (formValues.newPassword != formValues.confirmPassword) {
            const errorMessage = 'Confirm Password does not match with New Password';
            actions.setFieldError('confirmPassword', errorMessage);
            return
        }

        let payload = {
            emailID: mail,
            tempPassword: formValues.tempPassword,
            password: formValues.newPassword,
            isTemp: false
        }
        setLoading(true)
        instituteService.resetPassword(payload).then((res: any) => {
            setLoading(false)
            if (res?.status == 200) {
                if (res?.data === 1) {
                    navigate('/admin/login')
                    CommonToast({
                        title: 'Updated Successfully',
                        status: 'success',
                    });
                } else {
                    CommonToast({
                        description: 'Please try again later',
                        status: 'error',
                    })
                }
            }
        })

    }

    // const handleBack = () => {
    //     setChgPwd(false)
    // }

    // useEffect(() => {
    //     setResetpwd(resetPwd)
    //     setMail(instituteService.userDetails.emailID || instituteService.userDetails.email)
    // }, [resetPwd])
    return (
        // <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <DefaultAuth >
            {!resetpwd ?
                <Flex
                    w='100%'
                    maxW='max-content'
                    mx={{ base: 'auto', lg: '0px' }}
                    me='auto'
                    h='100%'
                    alignItems='start'
                    justifyContent='center'
                    mb={{ base: '30px', md: '60px', lg: '100px', xl: '60px' }}
                    px={{ base: '25px', md: '0px' }}
                    mt={{ base: '40px', lg: '16vh', xl: '22vh' }}
                    flexDirection='column'>
                    <Box me='auto' mb='34px'>
                        <Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb='16px'>
                            Forgot your password?
                        </Heading>
                    </Box>
                    <Flex
                        zIndex='2'
                        direction='column'
                        w={{ base: '100%', lg: '456px' }}
                        maxW='100%'
                        mb={{ base: '20px', md: 'auto' }}
                    >
                        <Formik
                            initialValues={{
                                emailID: '',
                            }}
                            validationSchema={validationSchemaforResetPwd}
                            onSubmit={resetPassword}>
                            <Form>
                                <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                    Email *
                                </FormLabel>
                                <Field name="emailID">
                                    {({ field, form }: any) => (

                                        <FormControl
                                            isInvalid={
                                                form.errors.emailID && form.touched.emailID
                                            }
                                        >
                                            <Input
                                                variant='auth'
                                                fontSize='sm'
                                                type='email'
                                                placeholder='Please Enter Email'
                                                size='lg'
                                                background={'#FFF'}
                                                borderRadius={'5px'}
                                                {...field}
                                            />
                                            <FormErrorMessage>
                                                {form.errors.emailID}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Text
                                    fontSize={'12px'}
                                    fontWeight={400}
                                    paddingTop={'12px'}
                                    color={'#000'}
                                    cursor="pointer"
                                >
                                    The Temporary password will be sent to your registered mail
                                </Text>
                                <VSeparator marginTop="30px" />

                                <Button
                                    fontSize='sm'
                                    isLoading={loading}
                                    backgroundColor={'#FF8700'}
                                    variant={'darkBrand'} fontWeight='500' w='100%' h='50' mb='24px' type="submit">
                                    Reset Password
                                </Button>
                            </Form>
                        </Formik>
                    </Flex>
                </Flex> :
                < >
                    {/* {resetPwd ?
                        <Flex >
                            <Button variant={'darkBrand'} width={'120px'} borderRadius={'29px'} onClick={handleBack} type={'button'} float={'right'} >Back</Button>
                        </Flex>
                        : ''} */}
                    <Flex
                        w='100%'
                        maxW='max-content'
                        mx={{ base: 'auto', lg: '0px' }}
                        me='auto'
                        h='100%'
                        alignItems='start'
                        justifyContent='center'
                        mb={{ base: '30px', md: '60px', lg: '100px', xl: '60px' }}
                        px={{ base: '25px', md: '0px' }}
                        mt={{ base: '40px', lg: '16vh', xl: '22vh' }}
                        flexDirection='column'>
                        <Box me='auto' mb='34px'>
                            <Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb='16px'>
                                Change Password
                            </Heading>
                        </Box>

                        <Flex
                            zIndex='2'
                            direction='column'
                            w={{ base: '100%', lg: '456px' }}
                            maxW='100%'
                            mb={{ base: '20px', md: 'auto' }}
                        >

                            <Formik
                                initialValues={{
                                    tempPassword: '',
                                    newPassword: '',
                                    confirmPassword: ''
                                }}
                                validationSchema={validationSchemaforChangePwd}
                                enableReinitialize={true}
                                onSubmit={changePassword}>
                                <Form>
                                    <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                        Temporary Password *
                                    </FormLabel>
                                    <Field name="tempPassword">
                                        {({ field, form }: any) => (
                                            <FormControl
                                                isInvalid={
                                                    form.errors.tempPassword && form.touched.tempPassword
                                                }
                                            >
                                                <InputGroup size="md">
                                                    <Input
                                                        fontSize="sm"
                                                        placeholder="Temporary Password"
                                                        size="lg"
                                                        type={show ? 'text' : 'password'}
                                                        variant="auth"
                                                        {...field}
                                                        background={'#FFF'}
                                                        borderRadius={'5px'}
                                                        autoComplete="on"
                                                    />
                                                    <InputRightElement
                                                        display="flex"
                                                        alignItems="center"
                                                        mt="4px"
                                                    >
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: 'pointer' }}
                                                            as={!show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={handleClick}
                                                            mr={3}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>
                                                    {form.errors.tempPassword}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <VSeparator marginTop="30px" />

                                    <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                        New Password *
                                    </FormLabel>
                                    <Field name="newPassword">
                                        {({ field, form }: any) => (
                                            <FormControl
                                                isInvalid={
                                                    form.errors.newPassword && form.touched.newPassword
                                                }
                                            >
                                                <InputGroup size="md">
                                                    <Input
                                                        // isRequired={true}
                                                        fontSize="sm"
                                                        placeholder="New Password"
                                                        size="lg"
                                                        type={showNewPassword ? 'text' : 'password'}
                                                        variant="auth"
                                                        {...field}
                                                        background={'#FFF'}
                                                        borderRadius={'5px'}
                                                        autoComplete="on"
                                                    />
                                                    <InputRightElement
                                                        display="flex"
                                                        alignItems="center"
                                                        mt="4px"
                                                    >
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: 'pointer' }}
                                                            as={!showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={() =>
                                                                setShowNewPassword(!showNewPassword)
                                                            }
                                                            mr={3}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>
                                                    {form.errors.newPassword}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <VSeparator marginTop="30px" />

                                    <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                        Confirm Password *
                                    </FormLabel>
                                    <Field name="confirmPassword">
                                        {({ field, form }: any) => (
                                            <FormControl
                                                isInvalid={
                                                    form.errors.confirmPassword && form.touched.confirmPassword
                                                }
                                            >
                                                <InputGroup size="md">
                                                    <Input
                                                        // isRequired={true}
                                                        fontSize="sm"
                                                        placeholder="Confirm Password"
                                                        size="lg"
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        variant="auth"
                                                        {...field}
                                                        background={'#FFF'}
                                                        borderRadius={'5px'}
                                                        autoComplete="on"
                                                    />
                                                    <InputRightElement
                                                        display="flex"
                                                        alignItems="center"
                                                        mt="4px"
                                                    >
                                                        <Icon
                                                            color={textColorSecondary}
                                                            _hover={{ cursor: 'pointer' }}
                                                            as={!showConfirmPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                            onClick={() =>
                                                                setShowConfirmPassword(!showConfirmPassword)
                                                            }
                                                            mr={3}
                                                        />
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>
                                                    {form.errors.confirmPassword}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <VSeparator marginTop="30px" />

                                    <Button fontSize='sm' backgroundColor={'#11047A'}
                                        variant={'darkBrand'} fontWeight='500' w='100%' h='50' mb='24px' type="submit"
                                        isLoading={loading}>
                                        Change Password
                                    </Button>

                                </Form>
                            </Formik>
                        </Flex>
                    </Flex>

                </>}
        </DefaultAuth>
    );
}

export default ForgotPassword;
