import CommonToast from "views/common/Toast";
import { Environment } from "./Environment";
import axiosInterceptor from "./axiosInterceptor";
import { Constants } from "./Constants";

class InstituteService {
    userID: any;
    instituteMasterID: any;
    employeemasterID: any;
    userDetails: any;
    parentInstituteMasterID: string;
    constructor() {
        this.setGlobals()
    }

    setGlobals(id?: string) {
        this.userID = localStorage.getItem('userID');
        this.instituteMasterID = id || localStorage.getItem('instituteMasterID');
        this.employeemasterID = localStorage.getItem('employeemasterID');
        this.userDetails = JSON.parse(localStorage.getItem('userdetails')) || {};
        this.parentInstituteMasterID = localStorage.getItem('parentInstituteMasterID');
    }

    getCurrentUser() {
        const userStr = localStorage.getItem('admin');
        try {
            if (userStr != undefined) return JSON.parse(userStr);
        } catch (error) {
            return null;
        }
        return null;
    }

    onBoardInstitute(encryptedkey: string) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'OnBoardInstitute', '', { headers: { Params: encryptedkey } })
            .then((response) => {
                console.log("response", response)
                return response.data
            })
            .catch((err) => {
                CommonToast({
                    title: 'Onboard Institute Failure',
                    description: 'Unable to Onboard Institute at this moment, please try again later',
                    status: 'error',
                });
            });
    }

    userSignIn(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UserSignIn', payload)
            .then((response) => response.data)
    }

    onBoardEmployee(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'OnBoardEmployee', payload)
            .then((response) => response.data)
    }

    getInstituteById(masterId: string) {
        return axiosInterceptor
            .get(Environment.erpinstituteurl + Constants.version1 + `GetInstitute?masterID=${masterId}`)
            .then((response) => response.data)
    }

    addorUpdateInstitute(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateInstitute', payload)
            .then((response) => response.data)
    }
    getAllBranches(masterID: string) {
        return axiosInterceptor
            .get(Environment.erpinstituteurl + Constants.version1 + `ViewAllInstitutes?masterID=${masterID}`)
            .then((response) => response.data)
    }
    getAllAdmissions(instId: string) {
        return axiosInterceptor
            .get(Environment.erpinstituteurl + Constants.version1 + `GetAllAdmissions?instituteMasterID=${instId}`)
            .then((response) => response.data)
    }

    upsertFeeType(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertFeeType', payload)
            .then((response) => response.data)
    }
    getDetailsByAdmissionID(AdmissionId: any) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAdmissionByID?AdmissionId=${AdmissionId}`).then((response) => response.data)

    }
    GetAllstudents(instId: string) {
        return axiosInterceptor
            .get(Environment.erpinstituteurl + Constants.version1 + `GetAllStudents?instituteMasterID=${instId}`)
            .then((response) => response.data)
    }
    getFeeTypes(instituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetFeeTypes?instituteMasterID=${instituteMasterID}`).then((response) => response.data)
    }

    getFeeInstMap(instituteMasterID: string, academicYearID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetFeeTypeInstituteMap?instituteMasterID=${instituteMasterID}&academicYearID=${academicYearID}`).then((response) => response.data)
    }

    insertFeeTypeInstituteMap(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'InsertFeeTypeInstituteMap', payload)
            .then((response) => response.data)
    }

    upsertDesignation(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertDesignation', payload)
            .then((response) => response.data)
    }

    getDesignation(parentInstituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetDesignation?ParentInstituteMasterID=${parentInstituteMasterID}`).then((response) => response.data)
    }

    upsertExpenseTypes(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertExpenseTypes', payload)
            .then((response) => response.data)
    }

    getExpenseTypes(instituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetExpenseTypes?instituteMasterID=${instituteMasterID}`).then((response) => response.data)
    }

    addorUpdateSubject(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateSubject', payload)
            .then((response) => response.data)
    }

    getSubjectList(instituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetSubjectList?instituteMasterID=${instituteMasterID}`).then((response) => response.data)
    }

    getSubjectInstituteMap(instituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetSubjectInstituteMap?instituteMasterID=${instituteMasterID}`).then((response) => response.data)
    }

    insertSubjectInstituteMap(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'InsertSubjectInstituteMap', payload)
            .then((response) => response.data)
    }

    getClassSubjectMap(classInstituteMapID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassSubjectMap?classInstituteMapID=${classInstituteMapID}`).then((response) => response.data)
    }

    upsertClassSubjectMap(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertClassSubjectMap', payload)
            .then((response) => response.data)
    }

    getSubjectByClassInstituteMap(classInstituteMapID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetSubjectByClassInstituteMap?classInstituteMapID=${classInstituteMapID}`).then((response) => response.data)
    }

    upsertDiscountType(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertDiscountType', payload)
            .then((response) => response.data)
    }

    getDiscountTypes(instituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetDiscountTypes?instituteMasterID=${instituteMasterID}`).then((response) => response.data)
    }

    addorUpdateClass(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateClass', payload)
            .then((response) => response.data)
    }

    getClassList(instituteID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassList?instituteID=${instituteID}`).then((response) => response.data)
    }

    getClassInstituteMap(instituteMasterID: string, academicYearID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassInstituteMap?instituteMasterID=${instituteMasterID}&academicYearID=${academicYearID}`).then((response) => response.data)
    }

    insertClassInstituteMap(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'InsertClassInstituteMap', payload)
            .then((response) => response.data)
    }

    addorUpdateClassSectionMap(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateClassSectionMap', payload)
            .then((response) => response.data)
    }

    getClassSectionMap(classInstituteMapID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassSectionMap?classInstituteMapID=${classInstituteMapID}`).then((response) => response.data)
    }

    addorUpdateRolePrivileges(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateRolePrivileges', payload)
            .then((response) => response.data)
    }

    getRoles(instituteID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetRoles?instituteID=${instituteID}`).then((response) => response.data)
    }

    getallPrivileges() {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAllPrivileges`).then((response) => response.data)
    }
    getPrivilegeByRole(roleId: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetPrivilegeByRole?roleID=${roleId}`).then((response) => response.data)
    }
    addorUpdateEnquiry(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateEnquiry', payload)
            .then((response) => response.data)
    }
    getAllEnquiries(instId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAllEnquiries?instituteMasterID=${instId}`).then((response) => response.data)
    }

    getEnquiryByID(enqId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetEnquiryByID?enquiryID=${enqId}`).then((response) => response.data)
    }

    upsertPreAssessment(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertPreAssessment', payload)
            .then((response) => response.data)
    }
    getPreAssessmentSchedule(enquiryid: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetPreAssessmentSchedule?enquiryID=${enquiryid}`).then((response) => response.data)
    }

    addorUpdateEnquiryFollowUp(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateEnquiryFollowUp', payload)
            .then((response) => response.data)
    }
    getAllEnquiryFollowUps(enquiryid: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAllEnquiryFollowUps?enquiryID=${enquiryid}`).then((response) => response.data)
    }
    updateEnquiryStatus(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpdateEnquiryStatus', payload)
            .then((response) => response.data)
    }


    upsertAdmission(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpsertAdmission', payload)
            .then((response) => response.data)
    }

    getAdmissionByID(admID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAdmissionByID?admissionID=${admID}`).then((response) => response.data)
    }
    insertStudent(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'InsertStudent', payload)
            .then((response) => response.data)
    }

    getEmployeeDetails(parentinstId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetEmployeeDetails?parentInstituteMasterID=${parentinstId}`).then((response) => response.data)
    }

    getEmployee(empMasterId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetEmployee?employeeMasterID=${empMasterId}`).then((response) => response.data)
    }

    addorUpdateEmployee(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateEmployee', payload)
            .then((response) => response.data)
    }

    getEmployeeInstituteMap(instmasterId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetEmployeeInstituteMap?instituteMasterID=${instmasterId}`).then((response) => response.data)
    }

    insertEmployeeInstituteMap(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'InsertEmployeeInstituteMap', payload)
            .then((response) => response.data)
    }

    getEmployeeInstituteMapByEmployeeID(employeeMasterID: string, instituteMasterID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetEmployeeInstituteMapByEmployeeID?employeeMasterID=${employeeMasterID}&instituteMasterID=${instituteMasterID}`).then((response) => response.data)
    }

    resetPassword(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'ResetPassword', payload)
            .then((response) => response.data)
    }

    forgotPassword(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'ForgotPassword', payload)
            .then((response) => response.data)
    }

    addOrUpdateStudentBulk(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddOrUpdateStudentBulk_V1', payload)
            .then((response) => response.data)
    }

    getStudentBulkUploadTemplate(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetStudentBulkUploadTemplate', payload)
            .then((response) => response.data)
    }

    getStudentUploadBatch(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetStudentUploadBatch', payload)
            .then((response) => response.data)
    }

    getStudentUploadBatchDetails(studentUploadBatchID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetStudentUploadBatchDetails?studentUploadBatchID=${studentUploadBatchID}`).then((response) => response.data)
    }

    getAllUsers(instmasterId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAllUsers?instituteMasterID=${instmasterId}`).then((response) => response.data)
    }

    addorUpdateUser(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateUser', payload)
            .then((response) => response.data)
    }

    getUser(userID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetUser?userID=${userID}`).then((response) => response.data)
    }

    getInstituteDetailsForLos(instId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetInstituteDetailsForLos?masterID=${instId}`).then((response) => response.data)
    }

    getFeeGroup(instmasterId: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetFeeGroup?instituteMasterID=${instmasterId}`).then((response) => response.data)
    }

    addorUpdateFeeGroup(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'AddorUpdateFeeGroup', payload)
            .then((response) => response.data)
    }

    getStudentFeeStructure(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetStudentFeeStructure', payload)
            .then((response) => response.data)
    }

    getAllInstituteTransactions(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetAllInstituteTransactionsForERPWeb', payload)
            .then((response) => response.data)
    }

    getTransactionDetails(transactionID: string) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetTransactionDetails?transactionID=${transactionID}`).then((response) => response.data)
    }

    getDhanvitaCollectionDashBoard(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetDhanvitaCollectionDashBoard', payload)
            .then((response) => response.data)
    }

    getFeeCollectionDashBoard(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetFeeCollectionDashBoard', payload)
            .then((response) => response.data)
    }

    getAllInstitute() {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAllInstitute`).then((response) => response.data)
    }

    getStudentFeeDashboard(instituteMasterID: string, academicYearID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetStudentFeeDashboard?instituteMasterID=${instituteMasterID}&academicYearID=${academicYearID}`).then((response) => response.data)
    }

    getFormattedPrice(price: string | number) {
        if (!price) {
            price = 0
        }
        let fPrice = Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
        }).format(Number(price));

        return fPrice?.replace("₹", "₹ ");
    }

    getClassWiseAutoPayDashboard(instituteMasterID: string, academicYearID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassWiseAutoPayDashboard?instituteMasterID=${instituteMasterID}&academicYearID=${academicYearID}`).then((response) => response.data)
    }

    getClassWiseFeeTransactionReport(instituteMasterID: string, academicYearID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassWiseFeeTransactionReport?instituteMasterID=${instituteMasterID}&academicYearID=${academicYearID}`).then((response) => response.data)
    }

    getClassWiseLoanReports(instituteMasterID: string, academicYearID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassWiseLoanDashBoard?instituteMasterID=${instituteMasterID}&academicYearID=${academicYearID}`).then((response) => response.data)
    }

    getRecentTransactionReport(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetRecentTransactionReport', payload)
            .then((response) => response.data)
    }

    getClassWiseAutoPayReport(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'GetClassWiseAutoPayReport', payload)
            .then((response) => response.data)
    }

    calculateSum(array: any, key: string) {
        console.log("value", array)
        const initialValue = 0;
        const sum = array.reduce(
            (accumulator: number, currentValue: any) =>
                accumulator + currentValue[key] || 0,
            initialValue,)
        return sum || 0
    }

    getStudentWiseAutoPayReport(instituteMasterID: string, classInstituteMapID: number) {
        return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetStudentWiseAutoPayReport?instituteMasterID=${instituteMasterID}&classInstituteMapID=${classInstituteMapID}`).then((response) => response.data)
    }

    updateStudentFeeDiscount(payload: any) {
        return axiosInterceptor
            .post(Environment.erpinstituteurl + Constants.version1 + 'UpdateStudentFeeDiscount', payload)
            .then((response) => response.data)
    }
}




let instituteService = new InstituteService();
export default instituteService;
