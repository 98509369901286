import {
    Button,
    Flex,
    Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const Expired = () => {
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate('/admin/login')
    }
    return (
        <>
            <Flex
                textAlign={'center'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={300}
            >
                <Text fontSize={30} paddingBottom={30}>Your Link has been Expired</Text>
                <Button
                    width={'30vh'}
                    borderRadius={'5px'}
                    variant="darkBrand"
                    backgroundColor={'#FF8700'}
                    color="white"
                    onClick={() => navigateHome()}>
                    OK
                </Button>
            </Flex>

        </>
    );
};
