/* eslint-disable */
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Text,
  List,
  Icon,
  ListItem,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import { FaCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { GetUserPrivileges } from 'store/actions/UserPrivilegesActions';
import React from 'react';
import { GetBranchesData } from 'store/actions/BranchActions';

const accBtnRefs: any = {};
let selectedIdx: string;
let branchActionCalled: boolean = false;
export function SidebarLinks(props: {

  routes: RoutesType[];
  [x: string]: any;
  UserPrivilegesStore: any;
  userPrivilegeAction: any;
  selectedInstituteId: string;
  branchesData: Array<any>;
  branchAction: any
}) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.600',
  );
  let activeIcon = useColorModeValue('orange.200', 'white');
  const [isMain, setIsMain] = useState(false);
  const { routes, hovered, mini, UserPrivilegesStore, selectedInstituteId, branchesData, branchAction } = props;
  //User Privileges from Store
  useEffect(() => {
    if (branchesData.length == 0 && !branchActionCalled) {
      branchActionCalled = true
      branchAction()
    }
    if (branchesData.length >= 1) {
      let a = branchesData.find((x: any) => x.masterID == selectedInstituteId)
      setIsMain(a?.isMainBranch);
    }
  }, [selectedInstituteId, branchesData]);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const onIconClick = (idx: string) => {
    selectedIdx = idx;
    let selectedRef: any = accBtnRefs?.[idx];
    let childNodeElems = selectedRef.childNodes[0];
    console.log('childnodeelessss', childNodeElems);

    setTimeout(_ => {
      if (childNodeElems?.childNodes?.[0]?.ariaExpanded == 'false') {
        if (childNodeElems) childNodeElems.lastChild.style.display = 'none';
      } else {
        if (childNodeElems) {
          console.log('child styleeee', childNodeElems.lastChild.style.height)
          childNodeElems.lastChild.style.display = 'block';
          childNodeElems.lastChild.style.height = 'auto';
          childNodeElems.lastChild.style.opacity = null;
        }
      }
    }, 100)
  }

  const setSideBarItems = () => {
    Object.keys(accBtnRefs).map((x: any) => {
      setTimeout(() => {
        let childNodeElems = accBtnRefs[x]?.childNodes[0];
        if (childNodeElems?.childNodes?.[0]?.ariaExpanded == 'false') {
          if (childNodeElems) childNodeElems.lastChild.style.display = 'none';
        }
        else {
          if (childNodeElems) {
            childNodeElems.lastChild.style.display = 'block';
            childNodeElems.lastChild.style.height = 'auto';
            childNodeElems.lastChild.style.opacity = null;
          }
        }
      }, 200)
    })
  }

  useEffect(() => {
    setSideBarItems()
  }, [hovered])

  const setRef = (ref: any, id: string) => {
    accBtnRefs[id] = ref;
  }

  const getCheck = (route: any) => {
    if (route.instSetting) {
      return isMain && UserPrivilegesStore.includes(route.privilege)
    }
    else {
      return UserPrivilegesStore.includes(route.privilege)
    }
  }

  const routeClicked = () => {
    setSideBarItems()
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route) => {
      if (getCheck(route)) {
        if (route.collapse) {
          return (
            <Accordion allowToggle key={route.id} ref={(r) => setRef(r, route.id)}>
              <AccordionItem maxW="100%" border="none" key={route.id}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    bg: 'unset',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  borderRadius="8px"
                  w={{
                    sm: '100%',
                    xl: '100%',
                    '2xl': '100%',
                  }}
                  px={route.icon ? null : '0px'}
                  py="0px"
                  bg={'transparent'}
                  ms={0}
                >
                  {route.icon ? (
                    <Flex
                      align="center"
                      justifyContent={
                        mini === false
                          ? 'space-between'
                          : mini === true && hovered === true
                            ? 'space-between'
                            : 'center'
                      }
                      w="100%"
                      onClick={() => { onIconClick(route.id) }}
                    >
                      <HStack
                        mb="6px"
                        spacing={
                          activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                        }
                      >
                        <Flex
                          w="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeIcon
                                : inactiveColor
                            }
                            me={
                              mini === false
                                ? '12px'
                                : mini === true && hovered === true
                                  ? '12px'
                                  : '0px'
                            }
                            mt="6px"
                          >
                            {route.icon}
                          </Box>
                          <Text
                            display={
                              mini === false
                                ? 'block'
                                : mini === true && hovered === true
                                  ? 'block'
                                  : 'none'
                            }
                            me="auto"
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeColor
                                : 'secondaryGray.600'
                            }
                            fontWeight="500"
                            fontSize="md"
                          >
                            {route.name}
                          </Text>
                        </Flex>
                      </HStack>
                      <AccordionIcon
                        display={
                          mini === false
                            ? 'block'
                            : mini === true && hovered === true
                              ? 'block'
                              : 'none'
                        }
                        ms="auto"
                        color={'secondaryGray.600'}
                        transform={route.icon ? null : 'translateX(-70%)'}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      pt="0px"
                      pb="10px"
                      justify={'center'}
                      alignItems="center"
                      w="100%"
                    >
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                        }
                        ps={
                          mini === false
                            ? '34px'
                            : mini === true && hovered === true
                              ? '34px'
                              : '0px'
                        }
                      >
                        <Text
                          me="auto"
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : inactiveColor
                          }
                          fontWeight="500"
                          fontSize="sm"
                        >
                          {mini === false
                            ? route.name
                            : mini === true && hovered === true
                              ? route.name
                              : route.name[0]}
                        </Text>
                      </HStack>
                      <AccordionIcon
                        display={
                          mini === false
                            ? 'block'
                            : mini === true && hovered === true
                              ? 'block'
                              : 'none'
                        }
                        ms="auto"
                        color={'secondaryGray.600'}
                        transform={null}
                      />
                    </Flex>
                  )}
                </AccordionButton>
                <AccordionPanel
                  display={
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                        ? 'block'
                        : 'flex'
                  }
                  justifyContent="center"
                  alignItems="center"
                  flexDirection={'column'}
                  // bg="blue"
                  pe={route.icon ? '14px !important' : '0px'}
                  py="0px"
                  ps={route.icon ? '14px !important' : '8px'}
                >
                  <List>
                    <>
                      {
                        route.icon
                          ? createLinks(route.items) // for bullet accordion links
                          : createAccordionLinks(route.items) // for non-bullet accordion links
                      }
                    </>
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        } else {
          return (
            <NavLink to={route.layout + route.path} key={route.id} onClick={routeClicked}>
              {route.icon ? (
                <Flex
                  align="center"
                  justifyContent="space-between"
                  w="100%"
                  ps="17px"
                  mb="0px"
                  className={route.class}
                >
                  <HStack
                    mb="6px"
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                    }
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : inactiveColor
                        }
                        me="12px"
                        mt="6px"
                      >
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : 'secondaryGray.600'
                        }
                        fontWeight="500"
                      >
                        {mini === false
                          ? route.name
                          : mini === true && hovered === true
                            ? route.name
                            : route.name[0]}
                      </Text>
                    </Flex>
                  </HStack>
                </Flex>
              ) : (
                <ListItem ms={null}>
                  <Flex
                    ps={
                      mini === false
                        ? '34px'
                        : mini === true && hovered === true
                          ? '34px'
                          : '0px'
                    }
                    alignItems="center"
                    mb="8px"
                  >
                    <Text
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight="500"
                      fontSize="sm"
                    >
                      {mini === false
                        ? route.name
                        : mini === true && hovered === true
                          ? route.name
                          : route.name[0]}
                    </Text>
                  </Flex>
                </ListItem>
              )}
            </NavLink>
          );
        }
      }

    });
  };
  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createAccordionLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType) => {
      return (
        <NavLink to={route.layout + route.path} key={route.id}>
          <ListItem
            ms={
              mini === false
                ? '28px'
                : mini === true && hovered === true
                  ? '28px'
                  : '0px'
            }
            display="flex"
            alignItems="center"
            mb="10px"
            key={route.id}
          >
            <Icon w="6px" h="6px" me="8px" as={FaCircle} color={activeIcon} />
            <Text
              display={
                mini === false
                  ? 'block'
                  : mini === true && hovered === true
                    ? 'block'
                    : 'none'
              }
              color={
                activeRoute(route.path.toLowerCase())
                  ? activeColor
                  : inactiveColor
              }
              fontWeight={
                activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
              }
              fontSize="sm"
            >
              {mini === false
                ? route.name
                : mini === true && hovered === true
                  ? route.name
                  : route.name[0]}
            </Text>
          </ListItem>
        </NavLink>
      );
    });
  };
  //  BRAND
  return <>{createLinks(routes)}</>;
}

const mapStateToProps = (state: any) => ({
  UserPrivilegesStore: state.userPrivileges.userPrivilegesData,
  selectedInstituteId: state.selectedinstituteID.instituteId,
  branchesData: state.branches.branchData,


});

export default connect(mapStateToProps, {
  branchAction: GetBranchesData
})(SidebarLinks);

